import { CheckCircleOutlineRounded  } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { DetalleSesion } from './DetalleSesion'
import { useState } from 'react'

export const ButtonDetalleSesion = ({ codSesion }) => {
  const [open, setOpen] = useState(false)
  const data = []
  // const { data, refetch } = useGetSemaforoByDni(dniEstudiante, false)
  const handleClickOpen = () => {
    setOpen(true)
    // refetch()
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <div>
      {/* <IconButton onClick={handleClickOpen}> */}
      <Tooltip title='Detalle de la sesión'>
        <IconButton onClick={handleClickOpen}>
          <CheckCircleOutlineRounded />
        </IconButton>
      </Tooltip>
      <DetalleSesion
        open={open}
        onClose={handleClose}
        data={data || []}
      />
    </div>
  )
}
