import React from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Slide,
  TextField,
  Typography
} from '@mui/material'
import { DataGridStyle } from '../../../../components/DataGridStyle'
import Input from '../../../../components/Input'
import { useForm } from 'react-hook-form'
// import { DataGridStyle } from '../../../../../components/DataGridStyle'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

export const DetalleSesion = props => {
  const { onClose, selectedValue, data, open } = props
  const columns = [
    {
      headerClassName: 'super-app-theme--header',
      field: 'nombres',
      headerName: 'Nombres',
      type: 'string',
      flex: 1
      // width: 180
    },
    {
      headerClassName: 'super-app-theme--header',
      field: 'asistencia',
      headerName: 'Asistencia',
      type: 'bool',
      flex: 1
      // width: 180,
      // renderCell: params => params.value.split('T')[0]
    },
    {
      headerClassName: 'super-app-theme--header',
      field: 'evaluacion',
      headerName: 'Evaluación',
      type: 'string',
      // width: 180
      flex: 1
    },
    {
      headerClassName: 'super-app-theme--header',
      field: 'objetivos',
      headerName: 'Objetivos',
      type: 'string',
      // width: 180
      flex: 1
    },
    {
      headerClassName: 'super-app-theme--header',
      field: 'comentariosAdicionales',
      headerName: 'Comentarios adicionales',
      type: 'string',
      // width: 180
      flex: 3
    }
    // {
    //   headerClassName: 'super-app-theme--header',
    //   field: 'semaforo',
    //   headerName: 'Semáforo',
    //   width: 370,
    //   type: 'string',
    //   renderCell: params => (
    //     <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
    //       <Circle sx={{ color: colorMapper[params.value] }} />
    //       <span>{semaforoMapper[params.value]}</span>
    //     </div>
    //   )
    // }
  ]

  const { control } = useForm()

  const handleClose = () => {
    onClose(selectedValue)
  }

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth={'md'}
        fullWidth={true}
      >
        <DialogTitle>
          <h1 style={{ fontSize: '16pt', margin: '15px 0' }}>
            Detalles de la sesión
          </h1>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Label label='Código de la sesión' value='5012' />
            </Grid>
            <Grid item xs={12}>
              <Label label='Fecha' value='09/10/2024' />
            </Grid>
            <Grid item xs={4}>
              <Label label='Hora inicio' value='09:30 AM' />
            </Grid>
            <Grid item xs={4}>
              <Label label='Hora fin' value='10:30 AM' />
            </Grid>
            <Divider />
            <Grid item xs={12}>
              <Input
                id='comentarios'
                label='Comentarios'
                control={control}
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={6}>
              <Input id='Evaluacion' label='Evaluación' control={control} />
            </Grid>
            <Grid item xs={6}>
              <Input id='Objetivo' label='Objetivo' control={control} />
            </Grid>
            <Divider />
            <Grid item xs={12}>
              <h2
                style={{
                  fontSize: '14pt',
                  fontWeight: 'bold',
                  margin: '7px 0 0 0'
                }}
              >
                Lista de Alumnos
              </h2>
            </Grid>
            <Grid item xs={12}>
              <DataGridStyle
                height={400}
                rows={[]}
                columns={columns}
                getRowId={row => row.idCita}
                experimentalFeatures={{ newEditingApi: true }}
                rowsPerPageOptions={[5]}
                pageSize={5}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'end' }}
            >
              <Button variant='contained'>Guardar</Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}

const Label = ({ label, value }) => {
  return (
    <>
      <span style={{ fontWeight: 'bold' }}>{label}: </span>
      <span>{value}</span>
    </>
  )
}
